import React, { FC, useState } from "react";
import { Image } from "../Image";
import { Trans } from "@lingui/macro";

interface DropdownItem {
  onClick?: () => void;
  disabled?: boolean;
}

const DropdownItem: FC<DropdownItem> = ({
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <div
      className={`px-2 py-1 block text-black hover:bg-gray-100 cursor-pointer ${
        disabled && "opacity-50"
      }`}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </div>
  );
};

const Divider = () => {
  return <hr className="border-t my-2 border-grey-ligght" />;
};

interface DropdownProps {
  className?: string;
  title: string;
  direction?: "botomLeft" | "bottomRight" | "topLett" | "topRight";
  type?: "menu" | "dropdown" | "attachment";
  minWidth?: string;
  visible?: boolean;
}

const directionClassName = {
  bottomLeft: "left-0",
  bottomRight: "right-0",
};

export const Dropdown: FC<DropdownProps> & {
  Item: typeof DropdownItem;
  Divider: typeof Divider;
} = ({
  className = "",
  title,
  children,
  direction = "bottomLeft",
  minWidth = "7rem",
  type = "dropdown",
  visible = false,
}) => {
  const [isOpen, setIsOpen] = useState(visible);

  return (
    <div
      className={`relative block lg:inline-block lg:mt-0 text-black ${className}`}
    >
      {isOpen && (
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(false);
          }}
          tabIndex={-1}
          className="fixed inset-0 h-full w-full cursor-default"
        ></button>
      )}
      <div
        className="flex justify-between items-center cursor-pointer border border-white border-b-0 rounded-t-lg"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <div className="whitespace-nowrap">{title}</div>

        {type === "dropdown" && (
          <svg
            className={`fill-current h-4 w-4 ml-2 ${
              isOpen ? "transform rotate-180" : ""
            } `}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title id="GWGLogoTitle">
              <Trans id="common.svg.title">GoWithGuide</Trans>
            </title>
            <desc id="GWGLogoDesc">
              <Trans id="common.svg.description">
                Find your perfect tour guide at GoWithGuide. Relax and cherish
                unforgettable tours with loved ones. Create personalized tours
                for a truly memorable trip!
              </Trans>
            </desc>
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        )}

        <div className="relative">
          {type == "menu" && (
            <Image
              src={`/icon/ic-more.png`}
              activeCache={true}
              width={25}
              height={25}
            />
          )}

          {type == "attachment" && (
            <Image
              src={`/icon/ic-attach.png`}
              width={30}
              height={30}
              activeCache={true}
            />
          )}
        </div>
      </div>
      <div
        className={`absolute w-full mt-2 z-10 items-center border rounded shadow-md p-1 bg-white ${
          directionClassName[direction]
        } ${isOpen ? "" : "hidden"}`}
        style={{ minWidth }}
      >
        {children}
      </div>
    </div>
  );
};

Dropdown.Item = DropdownItem;
Dropdown.Divider = Divider;
